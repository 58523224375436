<template>
  <div class="buy-payment">
    <van-config-provider>
      <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false">
        <template #right>
          <span @click="goToOrder">{{$t('title.order')}}</span>
        </template>
      </van-nav-bar>
    </van-config-provider>
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <div class="order-list">
        <van-list
          v-if="dataList.length >0"
          v-model:loading="loading"
          :finished="finished"
          :finished-text="$t('common.noMore')"
          @load="loadData"
        >
          <div class="order-block" v-for="item in dataList" :key="item.sn" @click="handleTakingPayment(item)">
            <div class="order-sn">{{item.sn}}</div>
            <div class="order-amount">{{item.amountStr}}PHP</div>
            <div class="order-time">{{item.bankName}}</div>
            <div class="order-status green">{{item.statusStr}}</div>
          </div>
        </van-list>
        <div v-else>
          <van-empty :description="$t('common.noData')"></van-empty>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Toast } from 'vant';
import useClipboard from 'vue-clipboard3';
import i18n from '@/assets/lang/index';
import {queryPaymentList, takingPayment } from "@/api";

export default {
  setup() {
    const { toClipboard } = useClipboard();
    const copy = async (text) => {
      try {
        await toClipboard(text);
        Toast(i18n.global.t('notify.copySuccess'));
      } catch (e) {
        console.error(e)
      }
    }

    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    return {
      copy,
      loading,
      finished,
      refreshing,
      dataList,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      amount: '',
      appTip: '',
      current: 1,
    };
  },
  
  mounted() {
    this.fetchOrderList();
  },

  methods: {
    fetchOrderList() {
      queryPaymentList({
        pageSizeStr: 10,
        pageNumberStr: this.current,
      }).then(res => {
        this.dataList = this.dataList.concat(res.data || []);
        this.current = res.pageNumber + 1;
        this.loading = false;
        if (res.totalNumber <= res.pageNumber * res.pageSize) {
          this.finished = true;
        }
      });
    },
    handleTakingPayment(order) {
      this.$myDialog.confirm({
        title: i18n.global.t('alert.warnning'),
        message: i18n.global.t('alert.buyPayment').format({
          1: order.amountStr,
          2: this.$AppData.config.appCoin,
        }),
      })
      .then(() => {// on confirm
        takingPayment({
          sn: order.sn
        }).then(res => {
          localStorage.setItem('paymentData', JSON.stringify(res.data));
          this.$router.push('/payment/detail');
        });
      })
      .catch(() => {});
    },
    loadData() {
      if (this.refreshing) {
        this.dataList = [];
        this.current = 1;
        this.refreshing = false;
      }
      this.fetchOrderList();
    },
    refreshData() {
      // 清空列表数据
      this.finished = false;
            
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    backColor(type) {
      let color = '#fff';
      this.$AppData.config.accountConfigVOList.map(item => {
        if(item.type === type) {
          color = item.color;
        }
      });
      return color;
    },
    goToOrder() {
      this.$router.push('/payment/order');
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.buy-payment {
  .order-list {
    padding: 14px;
    background: transparent;
    .order-block {
      background: #fff;
    }
  }
}
</style>
